








































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { GetCurrentUserData, PostUserData } from "@/request/account";
@Component({})
export default class Name extends Vue {
  private ifCompose: boolean = false;
  private user: any = {};
  private tempUser: any = {};
  /**
   * @description 获取用户信息
   */
  private fetchUserData() {
    GetCurrentUserData(this).then((data: any) => {
      this.user = data;
      this.$store.commit("updateUser", data);
    });
  }
  /**
   * @description 开始编辑
   */
  private startCompose() {
    this.tempUser = JSON.parse(JSON.stringify(this.user));
    this.ifCompose = true;
  }
  /**
   * @description 取消
   */
  private cancel() {
    this.ifCompose = false;
    this.tempUser = {};
  }
  /**
   * @description 保存
   */
  private save() {
    const params: any = JSON.parse(JSON.stringify(this.tempUser));
    PostUserData(this, params).then((data: any) => {
      this.$message.success("修改成功");
      this.tempUser = {};
      this.ifCompose = false;
      this.fetchUserData();
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.fetchUserData();
  }
}
